import React, { Component } from "react";
import Data from "./data";
import {
  LinearProgress,
  Typography,
  Button,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Select,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
} from "@material-ui/core";

class Header extends Component {
  render() {
    return (
      <div
        style={{
          background: "#ec1d24",
          height: "53px",
          paddingRight: "15px",
          paddingLeft: "15px",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <div
          style={{
            display: "inline-block",
            float: "left",
          }}
        >

          <div
            style={{
              float: "left",
              display: "inline-block",
              color: "#fff",
              height: "20px",
              lineHeight: "25px",
              position: "relative",
              padding: "5px 15px",
              marginTop: "11px",
              fontWeight: "lighter",
            }}
          >
            <span>Arneca Teknoloji | Mobil Raporlama CMS</span>
          </div>
        </div>
        <ul
          style={{
            float: "right",
            listStyle: "none",
            padding: 0,
            margin: 0,
            paddingRight: 30,
          }}
        >
          <li style={{ float: "left" }}>
            <div
              style={{
                display: "inline-block",
                float: "right",
                lineHeight: "53px",
              }}
            ></div>
          </li>
          <li
            style={{ float: "left", cursor: "pointer" }}
            onClick={this.logout}
          >
            <i
              className="material-icons"
              style={{
                margin: "12px 1px 12px 12px",
                fontSize: "30px",
                fontWeight: 600,
                color: "white",
              }}
            >
              power_settings_new
            </i>
          </li>
        </ul>
      </div>
    );
  }

  logout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };
}

export default Header;
