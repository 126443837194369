import React, {Component} from "react";
import Login from "./login"
import Header from "./header"
import Menu from "./menu"
import CategoryList from "./categoryList"
import ReportEdit from "./reportEdit"
import ReportList from "./reportList"
import UserList from "./users"
import AppValueList from "./appValueList"
import GroupList from "./groupList"
import LogList from "./logList"
import LoginList from "./loginList"

window.styles = {
  form: {
		display: 'flex',
		padding: 10,
		flexWrap: 'wrap'},
	block: {
		display: 'inline-block',
		padding: 10
	},
	selectFull: {
		width: 610	,
		marginRight: 10,
		marginBottom: 10
	},
	selectHalf: {
		width: 300	,
		marginRight: 10,
		marginBottom: 10
  },
  header: {
		background: 'url(./images/head-bg.png) #ec1d24',
		height: '53px',
		paddingRight: '15px',
		paddingLeft: '15px',
		marginRight: 'auto',
		marginLeft: 'auto'
	},
	logoWrap: {
		display: 'inline-block',
		float: 'left'
	},
	logo: {
		background: 'url(./images/logo2.png)',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '50% 50%',
		font: '0/0 -webkit-pictograph',
		height: '53px',
		width: '128px',
		display: 'block',
		float: 'left'
	},
	searchWrap: {
		display: 'inline-block',
		float: 'right',
		lineHeight: '53px'
	},
	appName: {
		float: 'left',
		display: 'inline-block',
		color: '#fff',
		height: '20px',
		lineHeight: '25px',
		position: 'relative',
		padding: '5px 15px',
		marginTop: '11px',
		fontWeight: 'lighter',
	},
	rightMenu: {
		float: 'right',
		listStyle: 'none',
		padding: 0,
		margin: 0,
		paddingRight: 30
	},
	logout:{
    margin: '12px 1px 12px 12px',
    fontSize: '30px',
    fontWeight: 600,
    color: 'white'
	},
	searchInput: {
		background: 'url(./images/search-icon.png) no-repeat 10px 5px #ffffff91',
		borderRadius: '20px',
		border: '0',
		lineHeight: '35px',
		padding: '0 15px 0 40px',
		color: 'white',
		outline: 'none',
		width: 240
	}

};

class App extends Component {
  constructor(props)
  {
    super(props)
		let token = localStorage.getItem('token');
    this.state = {
      login : token != null,
      page: <div />
    }
  }

  render() {
    if(!this.state.login)
      return <Login login={() => this.setState({login: true}) } />
    else 
    return <div>
      <Header />
      <Menu />
      <div style={{marginTop:10}}>
        <div className="container" style={{
					width: 'calc(100vw - 20vw)',
					marginLeft: '10vw',
					overflowX: 'auto',
					textAling: 'center',
					paddingTop: 100
				}}>
          {this.state.page}
        </div>
      </div>
    </div>
  }

  componentDidMount(){
    this.route()
    window.addEventListener("hashchange", this.route,false);
  }

  route = () => {
    var hash = window.location.hash
    
    if(hash === "#/categories")
      this.setState({page: <CategoryList />})
    else if(hash === "#/reports")
      this.setState({page: <ReportList />})
    else if(hash === "#/groups")
      this.setState({page: <GroupList />})
    else if(hash === "#/users")
      this.setState({page: <UserList />})
    else if(hash === "#/logs")
      this.setState({page: <LogList />})
    else if(hash === "#/settings")
      this.setState({page: <AppValueList />})
    else if(hash === "#/logins")
      this.setState({page : <LoginList />})
    else
    this.setState({page: <ReportList />})
  }
}


export default App