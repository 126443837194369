import React, { Component } from "react";
import {LinearProgress,
	Typography, Button, 
	TextField, MenuItem,
	Table, TableBody, TableCell,
	TableHead, TableRow,
	Paper, Select, Checkbox,FormControlLabel,
	FormControl, InputLabel } from "@material-ui/core";
import moment from "moment"
import Data from "./data"

class Menu extends Component {
	constructor(props){
		super(props)

		this.state = {open : false}
	}

	render() {
		return (
			<div className="menu">
				<input type="checkbox" href="#" checked={this.state.open}  className="menu-open" name="menu-open" id="menu-open" />
				<label className="menu-open-button" htmlFor="menu-open">
					<span className="hamburger hamburger-1"></span>
					<span className="hamburger hamburger-2"></span>
					<span className="hamburger hamburger-3"></span>
				</label>

				<a className="menu-item sub-item" href="#/reports">
					<i className="material-icons">insert_chart</i>
					<span className="title">Raporlar</span>
				</a>
				<a className="menu-item sub-item" href="#/categories">
					<i className="material-icons">label</i>
					<span className="title">Kategoriler</span>
				</a>
				<a className="menu-item sub-item" href="#/groups">
					<i className="material-icons">group_work</i>
					<span className="title">Gruplar</span>
				</a>
				<a className="menu-item sub-item" href="#/users">
					<i className="material-icons">people</i>
					<span className="title">Kullanıcılar</span>
				</a>
				<a className="menu-item  sub-item" href="#/logs">
					<i className="material-icons">archive</i>
					<span className="title">Loglar</span>
				</a>
				<a className="menu-item  sub-item" href="#/logins">
					<i className="material-icons">assignment_ind</i>
					<span className="title">Oturumlar</span>
				</a>
				<a className="menu-item  sub-item" href="#/settings">
					<i className="material-icons">settings</i>
					<span className="title">Ayarlar</span>
				</a>
			</div>)
	}

	componentDidMount(){
		this.setState({open: true})
	}
}

export default Menu