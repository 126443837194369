import req from "superagent";

export default {
  getUsers: async () => {
    var result = await req
      .get("https://zt.arneca.com/admin/getUsers")
      .set("Authorization", "Bearer " + localStorage.getItem('token'))

    return result.body.map(x => {x.password = ""; return x})
  },

  getCategories: async () => {
    var result = await req
      .get("https://zt.arneca.com/admin/getCategories")
      .set("Authorization", "Bearer " + localStorage.getItem('token'))

    return result.body
  },

  getCategoryReports: async query => {
    var result = await req
      .get("https://zt.arneca.com/admin/getCategoryReports")
      .set("Authorization", "Bearer " + localStorage.getItem('token'))
      .query(query)

    return result.body
  },

  getLogs: async (level, page, size, search) => {
    var result = await req
      .get("https://zt.arneca.com/admin/getLogs")
      .set("Authorization", "Bearer " + localStorage.getItem("token"))
      .query({ level, page, size, search })

    return result.body;

  },

  getLogins: async () => {
    var result = await req
      .get("https://zt.arneca.com/admin/getLogins")
      .set("Authorization", "Bearer " + localStorage.getItem("token"))

    return result.body;
  },

  getGroups: async () => {
    var result = await req
      .get("https://zt.arneca.com/admin/getGroups")
      .set("Authorization", "Bearer " + localStorage.getItem('token'))

    return result.body
  },

  getCharts: async () => {
    var result = await req
      .get("https://zt.arneca.com/admin/getCharts")
      .set("Authorization", "Bearer " + localStorage.getItem('token'))

    return result.body
  },

  getAppValues: async () => {
    var result = await req
      .get("https://zt.arneca.com/admin/getAppValues")
      .set("Authorization", "Bearer " + localStorage.getItem('token'))

    return result.body
  },

  saveUser: async user => {
    var result = await req
      .post("https://zt.arneca.com/admin/saveUser")
      .set("Authorization", "Bearer " + localStorage.getItem('token'))
      .send(user);

    user._id = result.body._id
    return user
  },

  saveReport: async report => {
    var result = await req
      .post("https://zt.arneca.com/admin/saveReport")
      .set("Authorization", "Bearer " + localStorage.getItem('token'))
      .send(report)

    return result.body
  },

  saveGroup: async group => {
    var result = await req
      .post("https://zt.arneca.com/admin/saveGroup")
      .set("Authorization", "Bearer " + localStorage.getItem('token'))
      .send(group)

    return result.body
  },

  saveCategory: async (cat) => {
    var result = await req
      .post("https://zt.arneca.com/admin/saveCategory")
      .set("Authorization", "Bearer " + localStorage.getItem('token'))
      .send(cat)

    return result.body
  },

  saveParentCategory: async (cat) => {
    var result = await req
      .post("https://zt.arneca.com/admin/saveParentCategory")
      .set("Authorization", "Bearer " + localStorage.getItem('token'))
      .send(cat)

    return result.body
  },

  saveAppValue: async (v) => {
    var result = await req
      .post("https://zt.arneca.com/admin/saveAppValue")
      .set("Authorization", "Bearer " + localStorage.getItem('token'))
      .send(v)

    return result.body
  },

  login: async (u) => {
    try {
      console.log("login")
      var result = await req
        .post("https://zt.arneca.com/admin/login")
        .send(u)

      if (result.body.token != null) {
        localStorage.setItem('token', result.body.token)
        return true
      }
      else return false
    } catch (e) {
      return false
    }
  }
}
