import React, { Component } from "react";
import {LinearProgress,
	Typography, Button, 
	TextField, MenuItem,
	Table, TableBody, TableCell,
	TableHead, TableRow,
	Paper, Select, Checkbox,FormControlLabel,
	FormControl, InputLabel } from "@material-ui/core";
import moment from "moment"
import Data from "./data"

class Login extends Component {
	constructor(props){
		super(props)

		this.state = {
			username: '',
			password: '',
			code: '1234'
		}
	}

	render() {
		return (
			<Paper style={{width: 400, height:240, padding:20,
          marginLeft: 'calc(50vw - 200px)', marginTop: 'calc(50vh - 200px)' }}>
        <Typography variant="display1" color="secondary">Arneca Report</Typography>
        <TextField value={this.state.username} label="Kullanıcı Adı" fullWidth 
          style={{marginBottom:30, marginTop: 10}}
          onChange={e => this.setState({username: e.target.value})}></TextField>
        <TextField value={this.state.password} label="Şifre" fullWidth 
          style={{marginBottom:30}} type="password"
          onChange={e => this.setState({password: e.target.value})}></TextField>
        <Button style={{float:'right'}} 
          variant="raised" color="secondary" onClick={this.login}>
          Giriş
        </Button>
      </Paper>
		)
  }

	login = async () => {
		console.log(this.state)
		try{
		  var success = await Data.login(this.state)
		  if(success)
			  this.props.login()
		}
		catch(e){
			this.setState({error: "Kullanıcı Adı/Şifre yanlış"})
		}
	}
}

export default Login