import React, { Component } from "react";
import {LinearProgress,
	Typography, Button, 
	TextField, MenuItem,
	Table, TableBody, TableCell,
	TableHead, TableRow,
	Paper, Select, Checkbox,FormControlLabel,
	FormControl, InputLabel } from "@material-ui/core";
import moment from "moment"
import Data from "./data"

class LogList extends Component {
	constructor(props){
		super(props)
		this.state = {
			count: 0,
			logs: [],
			size: 20,
			page: 0,
			search: "",
			level: 'all'
		}
	}
	render() {
		return (<div>
			<div >
				<Typography variant="display1" style={{display:'inline'}}>Loglar</Typography>
			</div>
			<div className="row">
				<Select style={{margin:10}}
					value={this.state.level} label="Seviye"
					onChange={e => this.selectLevel(e.target.value)}>
					<MenuItem key={0} value="all">Hepsi</MenuItem>
					<MenuItem key={1} value="information">Bilgi</MenuItem>
					<MenuItem key={2} value="warning">Uyarı</MenuItem>
					<MenuItem key={3} value="exception">Hata</MenuItem>
				</Select>
				<TextField id="search" className="validate" value={this.state.search} 
						style={{margin:10}} placeholder="Arama" 
						onChange={e => this.setSearch(e.target.value)} >
				</TextField >
				<Button variant="contained" color="secondary" 
					style={{margin:10}} onClick={this.getLogs} >
						<i className="material-icons left">search</i>Ara
				</Button>
			</div>
			<div>
			<Paper style={{}}>
				<Table style={{}}>
					<TableHead>
						<TableRow>
							<TableCell>Seviye</TableCell>
							<TableCell>Kullanıcı</TableCell>
							<TableCell>Aksiyon</TableCell>
							<TableCell>Açıklama</TableCell>
							<TableCell>Tarih</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.logs.map(l => {
							return (
								<TableRow key={l._id}>
									<TableCell>{l.Level}</TableCell>
									<TableCell>{l.User}</TableCell>
									<TableCell>{l.Action}</TableCell>
									<TableCell>{l.Detail}</TableCell>
									<TableCell>{moment(l.Date).format('HH:mm:ss DD.MM.YY')}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</Paper>
			</div>
		</div>)
	}

	async componentDidMount() {
		this.getLogs()
	}
	
	getLogs = async () => {
		var result = await Data.getLogs(
			this.state.level, 
			this.state.page + 1, 
			this.state.size, 
			this.state.search,
			this.state.logs
		)
		
		this.setState(result)		
	}

	selectLevel = lvl =>  this.setState({level: lvl}, this.getLogs)

	setSearch = search => this.setState({search})

	selectPage = page => this.setState({page}, this.getLogs)

	selectSize = size => this.setState({size}, this.getLogs)

	setLogs = logs => this.setState({logs}, this.getLogs)
	
}

export default LogList