import React, { Component } from "react";
import {LinearProgress,
	Typography, Button, 
	TextField, MenuItem,
	Table, TableBody, TableCell,
	TableHead, TableRow,
	Paper, Select, Checkbox,FormControlLabel,
	FormControl, InputLabel } from "@material-ui/core";
import moment from "moment"
import Data from "./data"

class LoginList extends Component {
	constructor(props){
		super(props)
		this.state = {
			logins : []
		}
	}

	render() {
		return (<div>
			<div >
					<Typography variant="display1" style={{display:'inline'}}>Oturumlar</Typography>
			</div>
			<div className="row">
				<div className="input-field col s3">
					<Button variant="contained" color="secondary" onClick={this.getLogins} >
						<i className="material-icons left">refresh</i>Yenile
					</Button>
				</div>
			</div>
			<div>
			<Paper style={{marginTop: 20}}>
				<Table >
					<TableHead>
						<TableRow>
							<TableCell>Kullanıcı</TableCell>
							<TableCell>İşletim Sistemi Versionu</TableCell>
							<TableCell>Son Giriş Tarihi</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.logins.map(l => {
							return (
								<TableRow key={l.Email}>
									<TableCell>{l.Email}</TableCell>
									<TableCell>{l.OsVersion}</TableCell>
									<TableCell>{moment(l.LastLogin).format('HH:mm:ss - DD.MM.YY')}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</Paper>
			</div>
		</div>)
	}

	async componentDidMount() {
		this.getLogins()
	}

	getLogins = async () => {
		var result = await Data.getLogins()
		
		this.setState({logins: result})		
	}
}

export default LoginList