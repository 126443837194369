import React, { Component } from "react";
import {LinearProgress,
	Typography, Button, 
	TextField, MenuItem,
	Table, TableBody, TableCell,
	TableHead, TableRow,
	Paper, Select, Checkbox,FormControlLabel,
	FormControl, InputLabel } from "@material-ui/core";
import moment from "moment"
import Data from "./data"
import ReportEdit from "./reportEdit";
	
class ReportList extends Component {
	constructor(props){
		super(props)
		this.state = {
			reports: [],
			categories: [],
			parent: "all",
			subId: "all",
			report: null,
			loading: true
		}
	}

	render() {
		var reports = this.state.reports
		if(this.state.loading) return <LinearProgress />
		if(this.state.report)
			return(<ReportEdit report={this.state.report} categories={this.state.categories}
				onBack={() => this.getReports({parent: "all", sub: "all"})} />)
		else
		return (<React.Fragment>
			<div>
          <Typography variant="display1" style={{display:'inline'}}>Raporlar</Typography>
          <div style={{display: 'inline', float: 'right', marginTop: 10}}>
						<Button variant="contained" color="secondary" onClick={this.addReport}>
							<i className="material-icons left">add</i>
							Rapor Ekle
						</Button>
          </div>  
      </div>
			<div className="row" style={{height: 80, marginTop: 20, marginBottom: 10}}>
				<FormControl style={{marginTop:10, width:300}}>
					<InputLabel focused={true} >Ana Kategori</InputLabel>
					<Select 
						value={this.state.parent } 
						onChange={e => this.selectParent(e.target.value)}>
						<MenuItem key={"all"} value={"all"}>Hepsi</MenuItem>
						{this.state.categories
							.map(c => (
							<MenuItem key={c._id} value={c._id}>
								{c.name}
							</MenuItem>
						))}	
					</Select>
				</FormControl>
				<FormControl style={{marginTop:10, marginLeft: 10, width:300}}>
					<InputLabel  focused={true}>Alt Kategori</InputLabel>
					<Select
						value={this.state.subId} 
						onChange={e => this.selectSub(e.target.value)}>
						<MenuItem key={"all"} value={"all"}>Seçilmedi</MenuItem>
						{this.state.parent != "all" &&
							this.state.categories.find(x => x._id == this.state.parent)
							.subCategories
							.filter(x => x.deleted == false)
							.map(c => (
							<MenuItem key={c._id} value={c._id}>
								{c.name}
							</MenuItem>
						))}	
					</Select> 
				</FormControl>
			</div>
			<Paper style={{padding: 10, background: 'white'}}>
				<Table className="responsive-table highlight striped">
					<TableHead>
						<TableRow>
							<TableCell>Rapor</TableCell>
							<TableCell>Kategori</TableCell>
							<TableCell>Chart</TableCell>
							<TableCell>Yetki</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{reports.map(r => {
							var catName = "kategori bulunamadı!"
							this.state.categories.forEach(pc =>
								{
                  var c = pc.subCategories
                    .filter(x => x.deleted == false)
                    .find(x => x._id == r.category)
									if(c != null) catName = pc.name + "/" + c.name
								}) 
			

							return (
								<TableRow key={r._id} onDoubleClick={() => this.setState({report: r})}>
									<TableCell>{r.name}</TableCell>
									<TableCell>{catName}</TableCell>
                                    <TableCell><img src={'./images/' + r.chart + ".png"} alt={r.chart} style={{width:40}} /></TableCell>
									<TableCell>
										{r.groups
											.join(', ')}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</Paper>
		</React.Fragment>)
	}

	async componentDidMount() {

		var groups = await Data.getGroups()
		this.setState({groups})
		await this.getCategories()
		await this.getReports({parent: "all", sub: "all"})
    this.setState({loading: false})
  }

  getCategories = async  () => {
    this.setState({
      loading: true,
      edit: null
    })
    var categories = await Data.getCategories()
		console.log({categories})
    this.setState({
      categories,
      loading: false
    })
	}
	
	getReports = async ({parent, sub}) => {
		console.log({parent, sub});
		var result = await Data.getCategoryReports({sub, parent})
		this.setState({
			report: null,
			reports: result
		})
	}

	selectParent = id => {
		this.setState({
			parent: id
		})
		this.getReports({parent:id, sub: "all"})
	}

	selectSub = id => {
		this.setState({
			subId: id
		})
		this.getReports({sub: id, parent: this.state.parent})
	}

	addReport = () => {
		
		var report = {
			name: '',
			description: '',
			sampleData: "",
			animation: "none",
			animation: "x",
			useSampleData: false,
			groups: [],
			url: '',
			deleted: false,
			category: null,
			chart: 'line'
		}
		this.setState({report})
	}
}

export default ReportList