import React, { Component } from "react";
import {LinearProgress,
	Typography, Button, 
	TextField, MenuItem,
	Table, TableBody, TableCell,
	TableHead, TableRow,
	Paper, Select, Checkbox,FormControlLabel,
	FormControl, InputLabel } from "@material-ui/core";
import Data from "./data"

class UserList extends Component {
	constructor(props){
		super(props)
		this.state = {
			loading: true,
			users: []
		}
	}

	
	render() {
		var users = this.state.users
		if (this.state.loading)
		return (<LinearProgress />)
		else if(this.state.user)
			return (<div>
				<div >
					<Typography variant="display1" style={{display:'inline'}}>Kullanıcı Düzenle</Typography>
				</div>
				<div className="row" style={{marginTop: 40, width:400, marginLeft: 0}}>
					<TextField value={this.state.user.name} label="İsim" fullWidth
								onChange={e => {
									this.state.user.name = e.target.value
									this.setState({user: this.state.user})
								}}></TextField>
					<TextField value={this.state.user.username} label="Kullanıcı Adı"
						fullWidth style={{marginTop: 10}}
							onChange={e => {
								this.state.user.username = e.target.value
								this.setState({user: this.state.user})
							}}></TextField>
					<TextField value={this.state.user.email} label="Email"
						fullWidth style={{marginTop: 10}}
							onChange={e => {
								this.state.user.email = e.target.value
								this.setState({user: this.state.user})
							}}></TextField>
					<TextField value={this.state.user.password} label="Şifre" type="password"
						fullWidth style={{marginTop: 10}}
							onChange={e => {
								this.state.user.password = e.target.value
								this.setState({user: this.state.user})
							}}></TextField>
					<FormControl fullWidth style={{marginTop:20}}>
						<InputLabel>Group</InputLabel>
						<Select style={{marginTop:10}} value={this.state.user.role} label="Grup"
							onChange={e => {
								this.state.user.role = e.target.value
								this.setState({user: this.state.user})
							}}>
							<option key={'admin'} value={'admin'}>
								Admin
							</option>
							{this.state.groups
								.map(c => (
								<option key={c.code} value={c.code}>
									{c.name}
								</option>
							))}	
						</Select>
					</FormControl>
					<div style={{marginTop:20}}>
						<Button variant="contained" color="secondary"
							onClick={async () => {
								if(this.state.wait) return
								try{
									this.setState({wait: true})
									await Data.saveUser(this.state.user)
									this.setState({wait: false})
									this.getUsers()
								}
								catch(e){
						
								}
							}} disabled={this.state.wait} >
							<i className="material-icons left">save</i>
							Kaydet
						</Button>
						{this.state.user._id != null &&
							<Button variant="outlined" color="secondary"
								style={{marginLeft: 20}}
								onClick={async () => {
									if(this.state.wait) return
									this.state.user.deleted = true
									this.setState({wait: true})
									await Data.saveUser(this.state.user)
									this.setState({wait: false})
									this.getUsers()
								}} disabled={this.state.wait} >
								<i className="material-icons left">delete</i>
								sil
							</Button>
						}
						<Button variant="outlined" color="default"
							style={{marginLeft: 20}}
							onClick={() => this.setState({user: null})} disabled={this.state.wait} >
							<i className="material-icons left">close</i>
							Vazgeç
						</Button>
					</div>
				</div>
			</div>)
		else return (<React.Fragment>
			<div >
				<Typography variant="display1" style={{display:'inline'}}>Kullanıcı Listesi</Typography>
				<div style={{display: 'inline', float: 'right', marginTop: 10}}>
					<Button variant="contained" color="secondary"
						onClick={this.addUser} >
						<i className="material-icons left">add</i>
						Kullanıcı Ekle
					</Button>
				</div>  
			</div>
			<Paper style={{padding: 10, margin:10, marginTop: 20, background: 'white'}}>
				<Table className="responsive-table highlight striped">
					<TableHead>
						<TableRow>
							<TableCell>İsim</TableCell>
							<TableCell>Grup</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users.map(g => {
							return (
								<TableRow key={g._id} onClick={()=> this.setState({user:g})}>
									<TableCell>{g.username}</TableCell>
									<TableCell>{g.role}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</Paper>
		</React.Fragment>)
	}

	async componentDidMount() {
		var users = await Data.getUsers()
		var groups = await Data.getGroups()
		this.setState({
			users, 
			groups,
			loading: false
		})
	}
	
	getUsers = async () =>{
		var users = await Data.getUsers()
		this.setState({
			user:null,
			users
		})
	} 

	addUser = () => {
		var user = {
			username: '',
			name: '',
			email: '',
			password: '',
			role: 'admin'
		}

		this.setState({user})
	}
}

export default UserList