import React, {Component} from "react"
import Data from "./data"
import {LinearProgress,
	Typography, Button, 
	TextField, MenuItem,
	Table, TableBody, TableCell,
	TableHead, TableRow,
	Paper, Select, Checkbox,FormControlLabel,
	FormControl, InputLabel } 
	from '@material-ui/core'

class GroupList extends Component {
	constructor(props){
		super(props)
		this.state = {
			groups: [],
			edit: null,
			loading: true
		}
	}

	render() {
		var groups = this.state.groups
		if (this.state.edit)
			return (<div>
				<div >
					<Typography variant="display1" style={{display:'inline'}}>Grup Düzenle</Typography>
				</div>
				<div className="row" style={{marginTop: 40, width:400, marginLeft: 0}}>
					<TextField value={this.state.edit.name} label="İsim" fullWidth
						onChange={e => {
							this.state.edit.name = e.target.value
							this.setState({edit: this.state.edit})
						}}></TextField>
					<TextField style={{marginTop: 10}}
						value={this.state.edit.code} label="Kod" fullWidth
						onChange={e => {
							this.state.edit.code = e.target.value
							this.setState({edit: this.state.edit})
						}}></TextField>
					<div style={{marginTop:20}}>
						<Button variant="contained" color="secondary"
							onClick={async () => {
								if(this.state.wait) return
								try{
									this.setState({wait: true})
									await Data.saveGroup(this.state.edit)
									this.setState({wait: false})
									this.getGroups()
								}
								catch(e){
						
								}
							}} disabled={this.state.wait} >
							<i className="material-icons left">save</i>
							Kaydet
						</Button>
						{this.state.edit._id > 0 &&
							<Button variant="outlined" color="secondary"
								style={{marginLeft: 20}}
								onClick={async () => {
									if(this.state.wait) return
									this.state.edit.deleted = true
									this.setState({wait: true})
									await Data.saveGroup(this.state.edit)
									this.setState({wait: false})
									this.getGroups()
								}} disabled={this.state.wait} >
								<i className="material-icons left">delete</i>
								sil
							</Button>
						}
						<Button variant="outlined" color="default"
							style={{marginLeft: 20}}
							onClick={() => this.setState({edit: null})} disabled={this.state.wait} >
							<i className="material-icons left">close</i>
							Vazgeç
						</Button>
					</div>
				</div>
			</div>)
		return (<React.Fragment>
			<div>
			<Typography variant="display1" style={{display:'inline'}}>Gruplar</Typography>
				<div style={{display: 'inline', float: 'right', marginTop: 10}}>
					<Button variant="contained" color="secondary" onClick={this.addGroup} >
						<i className="material-icons left">add</i>
						Grup Ekle
					</Button>
				</div>  
			</div>
			<div >
			<Paper style={{padding: 10, margin:10, marginTop: 20, background: 'white'}}>
				<Table className="responsive-table highlight striped">
					<TableHead>
						<TableRow>
							<TableCell>Grup Adı</TableCell>
							<TableCell>Grup Kodu</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{groups.map(g => {
							return (
								<TableRow key={g._id} onDoubleClick={() => this.setState({edit:g})}>
									<TableCell>{g.name}</TableCell>
									<TableCell>{g.code}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</Paper>
			</div>
		</React.Fragment>)
	}

	async componentDidMount() {
		this.getGroups()
	}

	getGroups = async ()=> {
		this.setState({
			loading: true,
			edit: null
		})
		var groups = await Data.getGroups()
		this.setState({
			groups,
			loading: false})
	}
	
	addGroup = async () => {
		this.setState({edit:{
			name: "",
			code: "",
			deleted: false
		}})
	}
}

export default GroupList