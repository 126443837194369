import React, {Component} from "react"
import Data from "./data"
import {LinearProgress,
	Typography, Button, 
	TextField, MenuItem,
	Table, TableBody, TableCell,
  TableHead, TableRow,
  List,ListItem, ListItemText,
	Paper, Select, Checkbox,FormControlLabel,
	FormControl, InputLabel } 
  from '@material-ui/core'
  
class CategoryList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      editParentCategory: null,
      editCategory: null,
      categories: [],
      selectedCategory: null
    }
  }

  render() {
    if (this.state.loading)
      return (<LinearProgress />)
    if (this.state.editCategory)
      return (<div>
          <div >
            <Typography variant="display1" style={{display:'inline'}}>Kategori Düzenle</Typography>
          </div>
          <div className="row" style={{marginTop: 40, width:400, marginLeft: 0}}>
            <TextField value={this.state.editCategory.name} label="İsim" fullWidth
                onChange={e => {
                  this.state.editCategory.name = e.target.value
                  this.setState({editCategory: this.state.editCategory})
                }}></TextField>
            <FormControl style={{marginTop:10}} fullWidth >
              <InputLabel>Üst Kategori</InputLabel>
              <Select style={{width:'100%'}}
                value={this.state.editCategory.parent || 0} 
                onChange={e => {
                  this.state.editCategory.parent = e.target.value
                  this.setState({editCategory: this.state.editCategory})
                }}>
                <MenuItem style={{marginTop:10}} key={0} value={0}>Yok</MenuItem>
                {this.state.categories
                  .filter(c => c._id !== this.state.editCategory._id)
                  .map(c => (
                  <MenuItem key={c._id} value={c._id} >
                    {c.name}
                  </MenuItem>
                ))}	
              </Select>
            </FormControl>
            <div style={{marginTop: 30}}>
              <Button variant="contained" color="secondary"
                onClick={async () => {
                  if(this.state.wait) return
                  try{
                    this.setState({wait: true})
                    await Data.saveCategory(this.state.editCategory)
                    this.setState({wait: false})
                    this.getCategories()
                  }
                  catch(e){
              
                  }
                }} disabled={this.state.wait} >
                <i className="material-icons left ">save</i>
                Kaydet
              </Button>
              {this.state.editCategory._id != null &&
                <Button variant="outlined" color="secondary"
                  style={{marginLeft: 20}}
                  onClick={async () => {
                    if(this.state.wait) return
                    this.state.editCategory.deleted = true
                    this.setState({wait: true})
                    await Data.saveCategory(this.state.editCategory)
                    this.setState({wait: false})
                    this.getCategories()
                  }} disabled={this.state.wait} >
                  <i className="material-icons left">delete</i>
                  sil
                </Button>
              }
              <Button variant="outlined" color="default"
                style={{marginLeft: 20}}
                onClick={() => this.setState({editCategory: null})} disabled={this.state.wait} >
                <i className="material-icons left">close</i>
                Vazgeç
              </Button>
            </div>
          </div>
        </div>)
    else if (this.state.editParentCategory)
      return (<div>
        <div >
          <Typography variant="display1" style={{display:'inline'}}>Kategori Düzenle</Typography>
        </div>
        <div className="row " style={{marginTop: 40, width: 400, marginLeft: 0}}>
          <TextField value={this.state.editParentCategory.name} label="İsim" fullWidth className="col s12"
              onChange={e => {
                this.state.editParentCategory.name = e.target.value
                this.setState({editParentCategory: this.state.editParentCategory})
              }}></TextField>
          <div style={{marginTop: 30}}>
            <Button variant="contained" color="secondary"
              onClick={async () => {
                if(this.state.wait) return
                try{
                  this.setState({wait: true})
                  await Data.saveParentCategory(this.state.editParentCategory)
                  this.setState({wait: false})
                  this.getCategories()
                }
                catch(e){
            
                }
              }} disabled={this.state.wait} >
              <i className="material-icons left">save</i>
              Kaydet
            </Button>
            {this.state.editParentCategory._id != null &&
              <Button variant="outlined" color="secondary"
                style={{marginLeft: 20}}
                onClick={async () => {
                  if(this.state.wait) return
                  this.state.editParentCategory.deleted = true
                  this.setState({wait: true})
                  await Data.saveParentCategory(this.state.editParentCategory)
                  this.setState({wait: false})
                  this.getCategories()
                }} disabled={this.state.wait} >
                <i className="material-icons left">delete</i>
                sil
              </Button>
            }
            <Button variant="outlined" color="default"
              style={{marginLeft: 20}}
              onClick={() => this.setState({editParentCategory: null})} disabled={this.state.wait} >
              <i className="material-icons left">close</i>
              Vazgeç
            </Button>
          </div>
        </div>
      </div>)
      else return (<React.Fragment>
        <div >
          <Typography variant="display1" style={{display:'inline'}}>Kategoriler</Typography>
          <div style={{display: 'inline', float: 'right', marginTop: 10}}>
            <Button variant="contained" color="secondary"
              onClick={this.addParentCategory} >
              <i className="material-icons left">add</i>
              Ana Kategori Ekle
            </Button>
            <Button variant="contained" color="secondary"
              style={{marginLeft: 10}} onClick={this.addCategory}  >
              <i className="material-icons left">add</i>
              Alt Kategori Ekle
            </Button>
          </div>  
        </div>
        <div className="row" style={{marginTop: 40}}>
          <div style={{margin: 10, width: "calc(40% - 10px)", display: "inline-block"}}>
            <Typography variant="title">Ana Kategoriler</Typography>
              <List component="nav" style={{ background: "white", marginTop: 20}}>
                {this.state.categories
                  .map(c =>
                  (<ListItem key={c._id} button
                    onClick={() => this.setState({selectedCategory: c})}
                    onDoubleClick={() => this.setState({editParentCategory:c}) }
                    style={{background: this.state.selectedCategory && this.state.selectedCategory._id == c._id ? "#f50057" : "white"}}>
                    <ListItemText primary={c.name} 
                      primaryTypographyProps={{color: this.state.selectedCategory && this.state.selectedCategory._id == c._id ? "white" : "black"}} />
                   </ListItem>)
                )}
              </List>
          </div>
          {this.state.selectedCategory &&
          <div style={{margin: 10, width: "calc(40% - 10px)", verticalAlign: "top", display: "inline-block"}}>
              <Typography variant="title">Alt Kategoriler</Typography>
              <List component="nav" style={{background: "white", marginTop: 20}}>
                {this.state.selectedCategory.subCategories
                  .filter(x => x.deleted == false)
                  .map(c =>
                  (<ListItem key={c._id} button
                    onDoubleClick={() => this.setState({editCategory:c})}>
                    <ListItemText primary={c.name} />
                   </ListItem>)
                )}
              </List>
          </div>}
        </div>
      </React.Fragment>)
  }

  componentDidMount() {
    this.getCategories()
  }
  
  getCategories = async  () => {
    this.setState({
      loading: true,
      editCategory: null,
      editParentCategory: null
    })
    var categories = await Data.getCategories()
    this.setState({
      categories,
      loading: false
    })
  }

  addCategory = async () => {
    var cat = this.state.selectedCategory
    this.setState({editCategory:{
      name: "",
      parent: cat != null ? cat._id : null,
      deleted: false
    }})
  }

  addParentCategory = async () => {
    this.setState({editParentCategory:{
      name: '',
      deleted: false
    }})
  }
}

export default CategoryList