import React, {Component} from "react"
import Data from "./data"
import {LinearProgress,
	Typography, Button, 
	TextField, MenuItem,
	Table, TableBody, TableCell,
	TableHead, TableRow,
	Paper, Select, Checkbox,FormControlLabel,
	FormControl, InputLabel } 
	from "@material-ui/core";


class AppValueList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			maxLogin: 10,
			tokenExpire: 10,
			smsExpire: 3,
			androidVersion: 1,
			iOsVersion: 1,
			androidLink: "",
			iOsLink: "",
			lastAdded: 5
		}
	}

	render() {
		if (this.state.loading)
			return (<LinearProgress />)
		else return (<React.Fragment>
			<div >
					<Typography variant="display1" style={{display:'inline'}}>Ayarlar</Typography>
			</div>
			<Paper style={{padding: 20, margin: 20, width: 700, paddingBottom: 60}}>
				<Table className="responsive-table highlight striped">
					<TableHead>
						<TableRow>
							<TableCell>Key</TableCell>
							<TableCell>Value</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Max Oturum Süresi (dk)</TableCell>
							<TableCell>{<input style={{width:300}} value={this.state.maxLogin} onChange={e => this.setState({"maxLogin": e.target.value})} />}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Token Ömrü (dk)</TableCell>
							<TableCell>{<input style={{width:300}} value={this.state.tokenExpire} onChange={e => this.setState({"tokenExpire": e.target.value})} />}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Sms Ömrü (dk)</TableCell>
							<TableCell>{<input style={{width:300}} value={this.state.smsExpire} onChange={e => this.setState({"smsExpire": e.target.value})} />}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Android Versiyonu</TableCell>
							<TableCell>{<input style={{width:300}} value={this.state.androidVersion} onChange={e => this.setState({"androidVersion": e.target.value})} />}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>iOS Versiyonu</TableCell>
							<TableCell>{<input style={{width:300}} value={this.state.iOsVersion} onChange={e => this.setState({"iOsVersion": e.target.value})} />}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Android Linki</TableCell>
							<TableCell>{<input style={{width:300}} value={this.state.androidLink} onChange={e => this.setState({"androidLink": e.target.value})} />}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>iOS Linki</TableCell>
							<TableCell>{<input style={{width:300}} value={this.state.iOsLink} onChange={e => this.setState({"iOsLink": e.target.value})} />}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Son Ekleme Gün Fitresi</TableCell>
							<TableCell>{<input style={{width:300}} value={this.state.lastAdded} onChange={e => this.setState({"lastAdded": e.target.value})} />}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Button variant="contained" color="secondary" style={{float: 'right', marginTop: 20}} onClick={this.save} >
					<i className="material-icons left">save</i>Kaydet
				</Button>
			</Paper>
			</React.Fragment>)
	}
	
	async componentDidMount() {
		var state = this.state
		var appValues = await Data.getAppValues()
		appValues.forEach(kv => state[kv.key] = kv.value)
		state.loading = false
		this.setState(this.state)
	}

	save = async () => {
		this.setState({
			loading: true
		})
		await Data.saveAppValue({key: 'maxLogin', value: this.state.maxLogin})
		await Data.saveAppValue({key: 'tokenExpire', value: this.state.tokenExpire})
		await Data.saveAppValue({key: 'smsExpire', value: this.state.smsExpire})
		await Data.saveAppValue({key: 'androidVersion', value: this.state.androidVersion})
		await Data.saveAppValue({key: 'iOsVersion', value: this.state.iOsVersion})
		await Data.saveAppValue({key: 'androidLink', value: this.state.androidLink})
		await Data.saveAppValue({key: 'iOsLink', value: this.state.iOsLink})
		await Data.saveAppValue({key: 'lastAdded', value: this.state.lastAdded})
		this.setState({
			loading: false
		})
	}
}

export default AppValueList