import React, { Component } from "react";
import {LinearProgress,
	Typography, Button, 
	TextField, MenuItem,
	Table, TableBody, TableCell,
	TableHead, TableRow,
	Paper, Select, Checkbox,FormControlLabel,
	FormControl, InputLabel } from "@material-ui/core";
import moment from "moment"
import Data from "./data"

class ReportEdit extends Component {
	constructor(props){
		super(props)

		var parentCatId = null;
		this.props.categories.forEach(c => {
			c.subCategories.forEach(sc => {
				if(sc._id == this.props.report.category){
          if(sc.deleted)
          {
            parentCatId = this.props.categories[0]._id
            this.props.report.category = this.props.categories[0].subCategories[0]._id
          }
          else
					  parentCatId = sc.parent
				}
			})
    })
    if(parentCatId == null){
      parentCatId = this.props.categories[0]._id
      this.props.report.category = this.props.categories[0].subCategories[0]._id
    }

		this.state = {
			categories: this.props.categories,
			report: this.props.report,
			wait: true,
			isEdited: false,
			charts: [],
			groups: [],
			parentCatId : parentCatId
		}
	}

  render() {
		console.log(this.state.parentCatId)
		if(this.state.wait) return <LinearProgress />
		else return (<React.Fragment>
			<div >
				<div style={{float:'left'}}>
					<Button variant="fab" color="secondary" onClick={() => this.back()}>
						<i className="material-icons">keyboard_arrow_left</i>
					</Button>
				</div>
        <Typography style={{marginLeft: 75}} variant="display1" gutterBottom>Raporlar</Typography>
			</div>
      <Paper style={{padding:20, paddingRight:10, width:1024, margin: '50px 30px 50px 80px', background:'white'}}>
        <div style={{display: "inline-block", width: "60%", verticalAlign: "top"}}>
					<TextField value={this.state.report.name} label="İsim" id="name" 
						style={{width: "calc(50% - 5px)", marginBottom: 10}}
            error={(this.state.report.name.length === 0)}
            onChange={e => this.setField("name", e.target.value)}></TextField>
          <TextField value={this.state.report.url} label="Servis Url" id="url" 
						error={this.state.report.url.length === 0} 
						style={{width: "calc(50% - 5px)", marginLeft: 10}}
            onChange={e => this.setField('url', e.target.value)}></TextField>
					<TextField value={this.state.report.description} label="Açıklama" id="desc"
						style={{width: "100%", marginBottom: 10}}
            onChange={e => this.setField("description", e.target.value)}></TextField>
					<FormControl style={{width: "calc(50% - 5px)", marginBottom: 10}}>
						<InputLabel>Ana Kategori</InputLabel>
						<Select 
							value={this.state.parentCatId || this.props.categories[0]._id} 
							value={this.state.parentCatId}
							onChange={e => {
								this.setState({parentCatId: e.target.value})
								var cat = this.props.categories.find(x => x._id == e.target.value)
								if(cat != null && cat.subCategories.length > 0)
								this.state.report.category = cat.subCategories[0]._id
							}}>
							{this.props.categories
								.map(c => (
								<MenuItem key={c._id} value={c._id}>
									{c.name}
								</MenuItem>
							))}	
						</Select>
					</FormControl>
					<FormControl style={{width: "calc(50% - 5px)",marginLeft:10}}>
						<InputLabel>Alt Kategori</InputLabel>
						<Select 
							value={this.state.report.category || this.props.categories[0].subCategories[0]._id} 
							value={this.state.report.category}
							onChange={e => this.setField('category', e.target.value)}>
							{this.state.parentCatId != null &&
								this.props.categories.find(x => x._id == this.state.parentCatId)
								.subCategories
								.filter(x => x.deleted == false)
								.map(c => (
								<MenuItem key={c._id} value={c._id}>
									{c.name}
								</MenuItem>
							))}	
						</Select> 
					</FormControl>
					<FormControl style={{width: "calc(50% - 5px)", marginBottom: 10}}>
						<InputLabel>Rapor Tipi</InputLabel>
						<Select 
							value={this.state.report.chart} 
							onChange={e =>this.setField('chart', e.target.value)}>
							{this.state.charts
								.map(c => (
								<MenuItem key={c} value={c}>
									{c}
								</MenuItem>
							))}	
						</Select>
					</FormControl>
					<FormControl  style={{width: "calc(50% - 5px)",marginLeft:10}} >
						<InputLabel>Animasyon</InputLabel>
						<Select 
							value={this.state.report.animation || 0}
							onChange={e => this.setField('animation', e.target.value)}>
							<MenuItem key={0} value="x">X</MenuItem>
							<MenuItem key={1} value="y">Y</MenuItem>
							<MenuItem key={2} value="xy">XY</MenuItem>
						</Select> 
					</FormControl>
					<FormControlLabel label="Örnek Datayı Kullan" 
						control={<Checkbox checked={this.state.report.useSampleData}
							onChange={(e => this.setField("useSampleData", e.target.checked))}>
						</Checkbox>} >
          </FormControlLabel>

          {this.state.report.useSampleData &&
            <FormControl style={{width:400, height:300}}>
							<InputLabel htmlFor="textarea1">Örnek Data</InputLabel>
              <textarea id="textarea1" className="materialize-textarea" rows={10}
                value={this.state.report.sampleData}
                style={{width:400, maxHeight:300, overflow: 'scroll'}}
                onChange={e => this.setField("sampleData", e.target.value)} ></textarea>
            </FormControl>
          }
          <div style={{marginTop: 30}}>
						<Button variant="outlined" color="secondary" onClick={() => this.delete()}>
							<i className="material-icons left">delete</i>Sil
						</Button>
						<Button variant="contained" color="secondary" style={{float:'right'}}
							 disabled={this.hasError()} onClick={() => this.save()}>
							<i className="material-icons left">save</i>Kaydet
						</Button>
          </div>
        </div>
        <div style={{display: "inline-block", marginLeft: 20, width: "30%", verticalAlign: "top"}}>
            <label>Yetki</label>
              {this.state.groups.map(group => (
                <div key={group.code}>
								<FormControlLabel label={group.name}
									control={<Checkbox checked={this.state.report.groups.indexOf(group.code) >= 0}
										onChange={e =>{
											var report = this.state.report
											if(!e.target.checked)
												report.groups = report.groups.filter(g => g != group.code)
											else if(report.groups.indexOf(group.code) <= 0 && e.target.checked)
												report.groups.push(group.code)
											this.setState({report})
											}
										}>
									</Checkbox>} >
								</FormControlLabel>
                </div>
              ))}
          </div>
      </Paper>

			</React.Fragment>
		)
	}

  async componentDidMount() {
      // var charts = await Data.getCharts();
		var groups = await Data.getGroups()
    var categories = await Data.getCategories();
  

    this.setState({
        charts: ["bar","line", "area", "pie", "dot", "table", "projectstatus", "server", "Map", "Financial", "branchInfo", "Html", "advanceBar", "genericList", "Project", "advGenericList"],
      groups, 
      categories, 
      wait:false,
      report: this.props.report
    })
  
  }

	componentWillReceiveProps(props){
		this.setState({
			report: props.report
		})
	}

	back = () => {
		this.props.onBack()
	}

	setField = async (name, value) => {
		this.state.report[name] = value
		this.state.isEdited = true
		this.setState(this.state)
	}

	
	save = async () => {
		try{


			if(!this.hasError()){
				this.setState({wait: true})
				var report = this.state.report
				
				var _report =	await Data.saveReport(this.state.report)
				report._id = _report._id
				this.back()
			}		
		}
		catch(e){
			alert(e.toString())
		}
	}

	hasError = () => (
		this.state.report.name.length === 0 ||
		this.state.report.url.length === 0 ||
		this.state.report.category === 0 || 
		this.state.report.category == null )

	delete = async () => {
		if(this.state.wait) return
		if(window.confirm("Silme işlemini onaylayın")){
			this.state.report.deleted = true
			this.setState({wait: true})
			await Data.saveReport(this.state.report)
			this.props.onBack()
		}
	}
}

export default ReportEdit